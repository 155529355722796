<template>
  <div class="d-row site-background">
    <div class="overlay" v-if="showingOverlay">
      <div class="pop-up d-flex-centered d-col" v-if="confirmingPrint">
        <div class="pop-up-title">
          <span>CONFIRM PRINTING</span>
        </div>
        <div class="pop-up-paragraph" style="max-width: 362px">
          <span
            >We need a second confirmation to start printing. Warning, you will
            not be able to go back after the confirmation.
          </span>
        </div>
        <div class="pop-up-buttons-container">
          <button
            class="pop-up-btn confirm"
            @click="confirmPrint()"
            style="margin-right: 12px"
          >
            I CONFIRM
          </button>
          <button class="pop-up-btn" @click="cancelPrint()">BACK</button>
        </div>
      </div>
      <div
        class="pop-up d-col"
        v-if="printpage"
        style="font-family: 'PT-serif'"
      >
        <div class="pop-up-title" style="text-align: center">
          <span>Final Check Before Printing</span>
        </div>
        <div
          class="pop-up-paragraph"
          style="max-width: 782px; text-align: left; font-family: 'PT-serif'"
        >
          <h2 style="text-align: center; margin-bottom: 25px">
            Please Read Carefully:
          </h2>
          <span
            >Before you proceed to share your shipping address, we need to make
            sure you are fully aware of the following important points:
          </span>
          <div style="margin: 25px">
            <ol>
              <li>
                Print As Is: Your book will be printed exactly as it appears in
                your preview. This includes all text, images, formatting, and
                overall layout.
              </li>
              <li>
                Emoji and Icon Restriction: Please note that emojis and other
                icons are not supported in our printing process. If included,
                these will be represented as "XX" characters in the final print.
                Carefully review your preview to ensure no emojis or unsuitable
                icons are present in your book.
              </li>
              <li>
                Correct Image Orientation: Ensure that all images uploaded are
                correctly oriented. It's your responsibility to verify that no
                pictures are reversed. If you find any images incorrectly
                displayed, adjust their orientation and re-upload them in your
                story.
              </li>
              <li>
                No Post-Submission Edits: After your book is submitted for
                printing, we cannot make any changes or edits. Ensure that you
                have thoroughly reviewed and are completely satisfied with your
                content.
              </li>
              <li>
                Review for Errors: We strongly recommend a final review of your
                book for typographical errors, grammatical mistakes, image
                quality concerns, or any other potential issues. We will not be
                responsible for such errors in the printed book.
              </li>
              <li>
                Final Approval: By providing your shipping address, you are
                affirming that you have reviewed your book in its entirety and
                approve it for printing as is.
              </li>
            </ol>
          </div>
          <span>
            Please tick the box below to acknowledge that you have read,
            understood, and agree to these terms.
          </span>
          <div
            style="
              background-color: rgba(248, 244, 241, 1);
              margin-top: 20px;
              padding: 20px;
            "
          >
            <span style="margin: 20px">
              <input
                type="checkbox"
                id="validContract"
                v-model="validContract"
              />
              <label for="validContract" style="margin-left: 10px"
                >I have read and agree to the terms stated above and understand
                that my book will be printed exactly as submitted, with no
                possibility for modifications post-submission.</label
              >
            </span>
          </div>
        </div>
        <div class="pop-up-buttons-container">
          <button
            class="pop-up-btn confirm"
            @click="confirmContract()"
            style="margin-right: 12px"
          >
            AGREE & CONTINUE
          </button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="successPrint">
        <div class="pop-up-paragraph" style="max-width: 362px">
          <span>{{ successParagraph }} </span>
        </div>
        <div class="d-row d-flex-centered">
          <button
            class="pop-up-btn confirm"
            @click="hideSuccessPanel()"
            style="margin-right: 12px; background: #062a20"
          >
            CONTINUE
          </button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="errorPrint">
        <div style="height: 68px; margin-bottom: 26px">
          <img
            src="../assets/alert.png"
            alt=""
            style="height: 68px; width: 68px"
          />
        </div>
        <div class="pop-up-title">
          <span>{{ errorReason }}</span>
        </div>
        <div class="pop-up-paragraph" style="max-width: 362px">
          <span>{{ errorParagraph }} </span>
        </div>
        <div class="d-row d-flex-centered">
          <button
            class="pop-up-btn confirm"
            @click="hideErrorPanel()"
            style="margin-right: 12px; background: #e1504b"
          >
            Understood
          </button>
        </div>
      </div>
      <div v-if="loading">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <navbar></navbar>

    <div class="container">
      <!--
        <div class="popup-info-container" v-if="popupinfo">
        <div class="popup-info">
          <div>
            Please note, the shipping cut-off date has passed. <br /><br />
            We cannot guarantee Christmas delivery for any book submissions made
            after December 2nd.
          </div>
          <div @click="popupinfo = false">X</div>
        </div>
      </div>-->
      <div class="d-flex-centered d-col route-section" style="">
        <h1 style="font-size: 24px; width: 100%" class="mobile title-section">
          Print
        </h1>
        <div class="print-section-container d-col" v-show="step == 1">
          <div
            class="d-row"
            style="
              justify-content: space-between;
              margin-bottom: 10px;
              font-size: 12px;
              line-height: 18px;
            "
          >
            <div class="ptr" style="color: #e1504b" @click="step = 1">
              Shipping
            </div>
            <div
              class="ptr"
              style="color: rgba(6, 42, 32, 0.7)"
              @click="goToNextStep()"
            >
              Confirmation
            </div>
          </div>
          <div class="d-row" style="width: 100%; margin-bottom: 10px">
            <div
              class="ptr"
              style="
                width: 50%;
                height: 3px;
                background: #e1504b;

                position: relative;
              "
              @click="step = 1"
            ></div>
            <div
              class="ptr"
              style="
                width: 50%;
                height: 3px;
                background: #e5e9e8;

                position: relative;
              "
              @click="goToNextStep()"
            ></div>
          </div>
          <div class="form-title-container">
            <span style="text-align: center">SHIPPING ADDRESS</span>
          </div>
          <div style="margin-bottom: 10px">
            <span>Your account include {{ this.copy }} book. </span>
          </div>
          <div class="print-form-section error-msg-container">
            <span class="print-form-error" v-if="errorMessage1">{{
              errorMessage1
            }}</span>
          </div>
          <div class="print-form-section error-msg-container">
            <span class="print-form-success" v-if="successSaved">
              Your address has been successfully saved
            </span>
          </div>
          <div class="print-form-section">
            <input
              type="text"
              placeholder="Name"
              v-model="adressName"
              class="print-input"
              :class="{ 'form-input-error': nameError }"
              ref="nameInput"
              @keypress.enter="validateStep1()"
            />
          </div>
          <div class="print-form-section">
            <input
              type="tel"
              placeholder="Phone number"
              v-model="phoneNumber"
              class="print-input"
              :class="{ 'form-input-error': phoneNumberError }"
              ref="phoneNumberInput"
              @keypress.enter="validateStep1()"
              required
            />
          </div>
          <div class="print-form-section">
            <input
              type="text"
              placeholder="Address line 1"
              v-model="streetLine1"
              class="print-input"
              :class="{ 'form-input-error': streetError }"
              ref="streetInput"
              @keypress.enter="validateStep1()"
            />
          </div>
          <div class="print-form-section">
            <select
              class="print-input"
              :class="{ 'form-input-error': countryError }"
              v-model="country"
              ref="countryInput"
            >
              <option
                class="rounded-full"
                value="country"
                disabled
                selected="selected"
              >
                Country
              </option>
              <option value="usa">United States</option>
              <option value="czech republic">Czech Republic</option>
              <option value="canada">Canada</option>
              <option value="uk">UK</option>
              <option value="new zealand">New Zealand</option>
              <option value="australia">Australia</option>
              <option value="ireland">Ireland</option>
              <option value="south africa">South Africa</option>
              <option value="germany">Germany</option>
              <option value="sweden">Sweden</option>
              <option value="netherlands">Netherlands</option>
              <option value="afghanistan">Afghanistan</option>
              <option value="albania">Albania</option>
              <option value="algeria">Algeria</option>
              <option value="andorra">Andorra</option>
              <option value="angola">Angola</option>
              <option value="antigua and barbuda">Antigua and Barbuda</option>
              <option value="argentina">Argentina</option>
              <option value="armenia">Armenia</option>
              <option value="austria">Austria</option>
              <option value="azerbaijan">Azerbaijan</option>
              <option value="bahamas">Bahamas</option>
              <option value="bahrain">Bahrain</option>
              <option value="bangladesh">Bangladesh</option>
              <option value="barbados">Barbados</option>
              <option value="belarus">Belarus</option>
              <option value="belgium">Belgium</option>
              <option value="belize">Belize</option>
              <option value="benin">Benin</option>
              <option value="bhutan">Bhutan</option>
              <option value="bolivia">Bolivia</option>
              <option value="bosnia and herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="botswana">Botswana</option>
              <option value="brazil">Brazil</option>
              <option value="brunei">Brunei</option>
              <option value="bulgaria">Bulgaria</option>
              <option value="burkina faso">Burkina Faso</option>
              <option value="burundi">Burundi</option>
              <option value="cabo verde">Cabo Verde</option>
              <option value="cambodia">Cambodia</option>
              <option value="cameroon">Cameroon</option>
              <option value="central african republic">
                Central African Republic
              </option>
              <option value="chad">Chad</option>
              <option value="chile">Chile</option>
              <option value="china">China</option>
              <option value="colombia">Colombia</option>
              <option value="comoros">Comoros</option>
              <option value="congo">Congo</option>
              <option value="costa rica">Costa Rica</option>
              <option value="croatia">Croatia</option>
              <option value="cuba">Cuba</option>
              <option value="cyprus">Cyprus</option>
              <option value="democratic republic of the congo">
                Democratic Republic of the Congo
              </option>
              <option value="denmark">Denmark</option>
              <option value="djibouti">Djibouti</option>
              <option value="dominica">Dominica</option>
              <option value="dominican republic">Dominican Republic</option>
              <option value="ecuador">Ecuador</option>
              <option value="egypt">Egypt</option>
              <option value="el salvador">El Salvador</option>
              <option value="equatorial guinea">Equatorial Guinea</option>
              <option value="eritrea">Eritrea</option>
              <option value="estonia">Estonia</option>
              <option value="eswatini">Eswatini</option>
              <option value="ethiopia">Ethiopia</option>
              <option value="fiji">Fiji</option>
              <option value="finland">Finland</option>
              <option value="france">France</option>
              <option value="gabon">Gabon</option>
              <option value="gambia">Gambia</option>
              <option value="georgia">Georgia</option>
              <option value="ghana">Ghana</option>
              <option value="greece">Greece</option>
              <option value="grenada">Grenada</option>
              <option value="guatemala">Guatemala</option>
              <option value="guinea">Guinea</option>
              <option value="guinea-bissau">Guinea-Bissau</option>
              <option value="guyana">Guyana</option>
              <option value="haiti">Haiti</option>
              <option value="honduras">Honduras</option>
              <option value="hungary">Hungary</option>
              <option value="iceland">Iceland</option>
              <option value="india">India</option>
              <option value="indonesia">Indonesia</option>
              <option value="iran">Iran</option>
              <option value="iraq">Iraq</option>
              <option value="israel">Israel</option>
              <option value="italy">Italy</option>
              <option value="jamaica">Jamaica</option>
              <option value="japan">Japan</option>
              <option value="jordan">Jordan</option>
              <option value="kazakhstan">Kazakhstan</option>
              <option value="kenya">Kenya</option>
              <option value="kiribati">Kiribati</option>
              <option value="kuwait">Kuwait</option>
              <option value="kyrgyzstan">Kyrgyzstan</option>
              <option value="laos">Laos</option>
              <option value="latvia">Latvia</option>
              <option value="lebanon">Lebanon</option>
              <option value="lesotho">Lesotho</option>
              <option value="liberia">Liberia</option>
              <option value="libya">Libya</option>
              <option value="liechtenstein">Liechtenstein</option>
              <option value="lithuania">Lithuania</option>
              <option value="luxembourg">Luxembourg</option>
              <option value="madagascar">Madagascar</option>
              <option value="malawi">Malawi</option>
              <option value="malaysia">Malaysia</option>
              <option value="maldives">Maldives</option>
              <option value="mali">Mali</option>
              <option value="malta">Malta</option>
              <option value="marshall islands">Marshall Islands</option>
              <option value="mauritania">Mauritania</option>
              <option value="mauritius">Mauritius</option>
              <option value="mexico">Mexico</option>
              <option value="micronesia">Micronesia</option>
              <option value="moldova">Moldova</option>
              <option value="monaco">Monaco</option>
              <option value="mongolia">Mongolia</option>
              <option value="montenegro">Montenegro</option>
              <option value="morocco">Morocco</option>
              <option value="mozambique">Mozambique</option>
              <option value="myanmar">Myanmar</option>
              <option value="namibia">Namibia</option>
              <option value="nauru">Nauru</option>
              <option value="nepal">Nepal</option>
              <option value="nicaragua">Nicaragua</option>
              <option value="niger">Niger</option>
              <option value="nigeria">Nigeria</option>
              <option value="north korea">North Korea</option>
              <option value="north macedonia">North Macedonia</option>
              <option value="norway">Norway</option>
              <option value="oman">Oman</option>
              <option value="pakistan">Pakistan</option>
              <option value="palau">Palau</option>
              <option value="palestine state">Palestine State</option>
              <option value="panama">Panama</option>
              <option value="papua new guinea">Papua New Guinea</option>
              <option value="paraguay">Paraguay</option>
              <option value="peru">Peru</option>
              <option value="philippines">Philippines</option>
              <option value="poland">Poland</option>
              <option value="portugal">Portugal</option>
              <option value="qatar">Qatar</option>
              <option value="romania">Romania</option>
              <option value="russia">Russia</option>
              <option value="rwanda">Rwanda</option>
              <option value="saint kitts and nevis">
                Saint Kitts and Nevis
              </option>
              <option value="saint lucia">Saint Lucia</option>
              <option value="saint vincent and the grenadines">
                Saint Vincent and the Grenadines
              </option>
              <option value="samoa">Samoa</option>
              <option value="san marino">San Marino</option>
              <option value="sao tome and principe">
                Sao Tome and Principe
              </option>
              <option value="saudi arabia">Saudi Arabia</option>
              <option value="senegal">Senegal</option>
              <option value="serbia">Serbia</option>
              <option value="seychelles">Seychelles</option>
              <option value="sierra leone">Sierra Leone</option>
              <option value="singapore">Singapore</option>
              <option value="slovakia">Slovakia</option>
              <option value="slovenia">Slovenia</option>
              <option value="solomon islands">Solomon Islands</option>
              <option value="somalia">Somalia</option>
              <option value="south korea">South Korea</option>
              <option value="south sudan">South Sudan</option>
              <option value="spain">Spain</option>
              <option value="sri lanka">Sri Lanka</option>
              <option value="sudan">Sudan</option>
              <option value="suriname">Suriname</option>
              <option value="switzerland">Switzerland</option>
              <option value="syria">Syria</option>
              <option value="taiwan">Taiwan</option>
              <option value="tajikistan">Tajikistan</option>
              <option value="tanzania">Tanzania</option>
              <option value="thailand">Thailand</option>
              <option value="timor-leste">Timor-Leste</option>
              <option value="togo">Togo</option>
              <option value="tonga">Tonga</option>
              <option value="trinidad and tobago">Trinidad and Tobago</option>
              <option value="tunisia">Tunisia</option>
              <option value="turkey">Turkey</option>
              <option value="turkmenistan">Turkmenistan</option>
              <option value="tuvalu">Tuvalu</option>
              <option value="uganda">Uganda</option>
              <option value="ukraine">Ukraine</option>
              <option value="united arab emirates">United Arab Emirates</option>
              <option value="uruguay">Uruguay</option>
              <option value="uzbekistan">Uzbekistan</option>
              <option value="vanuatu">Vanuatu</option>
              <option value="venezuela">Venezuela</option>
              <option value="vietnam">Vietnam</option>
              <option value="yemen">Yemen</option>
              <option value="zambia">Zambia</option>
              <option value="zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <div class="print-form-section">
            <input
              type="text"
              placeholder="City"
              v-model="city"
              class="print-input"
              :class="{ 'form-input-error': cityError }"
              ref="cityInput"
              @keypress.enter="validateStep1()"
            />
          </div>
          <div class="print-form-section">
            <input
              type="text"
              placeholder="State"
              v-model="state"
              class="print-input"
              ref="stateInput"
              @keypress.enter="validateStep1()"
            />
          </div>
          <div class="print-form-section">
            <input
              type="text"
              placeholder="Zip code"
              v-model="zipCode"
              class="print-input"
              :class="{ 'form-input-error': zipCodeError }"
              ref="zipCodeInput"
              @keypress.enter="validateStep1()"
            />
          </div>
          <div class="print-form-section spaced d-row d-flex-centered">
            <button
              @click="goToNextStep()"
              style="background-color: #e1504b; margin-right: 10px"
              class="login-btn"
            >
              NEXT
            </button>
            <button @click="saveShippingAdress()" class="login-btn">
              SAVE
            </button>
          </div>
        </div>
        <div class="d-col print-form-container" v-show="step == 2">
          <div
            class="d-row"
            style="
              justify-content: space-between;
              margin-bottom: 10px;
              font-size: 12px;
              line-height: 18px;
            "
          >
            <div
              class="ptr"
              style="color: rgba(6, 42, 32, 0.7)"
              @click="goToPrviewsStep()"
            >
              Shipping
            </div>
            <div class="ptr" style="color: #e1504b" @click="step = 2">
              Confirmation
            </div>
          </div>
          <div class="d-row" style="width: 100%; margin-bottom: 10px">
            <div
              class="ptr"
              style="
                width: 50%;
                height: 3px;
                background: #e5e9e8;
                position: relative;
              "
              @click="goToPrviewsStep()"
            ></div>
            <div
              class="ptr"
              style="
                width: 50%;
                height: 3px;
                background: #e1504b;
                position: relative;
              "
              @click="step = 2"
            ></div>
          </div>
          <div class="main-section-container" style="text-align: center">
            <div class="right-side-print">
              <div class="form-title-container">
                <span style="text-align: center">CONFIRM PRINTING</span>
              </div>
              <div class="print-confirm-btn-mobile">
                <button
                  class="confirm-impression-btn ptr"
                  @click="startConfirmingPrint()"
                  :class="{
                    disabled: copy < 0,
                  }"
                  :disabled="copy < 1"
                >
                  CONFIRM PRINTING
                </button>
              </div>
              <div style="margin-bottom: 18px">
                <span class="print-paragraph">
                  Congrats! This is the last step, you can either go back to
                  make changes or start printing your book by clicking on the
                  button below.
                </span>
              </div>
              <div class="book-stats-container">
                <div style="margin-bottom: 5px">
                  <span
                    >Total number of stories:
                    <span style="color: #e1504b">{{ storiesCount }}</span>
                  </span>
                </div>
                <div style="margin-bottom: 5px">
                  <span
                    >Total number of pictures:
                    <span style="color: #e1504b">{{ imagesCount }}</span>
                  </span>
                </div>
                <div style="margin-bottom: 5px">
                  <span
                    >Total number of pages:
                    <span style="color: #e1504b">{{ pagesCount }}</span>
                  </span>
                </div>
                <div style="margin-top: 20px" v-if="copy < 1">
                  <span>
                    <span
                      style="
                        color: #e1504b;
                        font-size: 12px;
                        font-style: italic;
                      "
                      >You can’t print more book as you don’t have any credits
                      left in your account. To purchase print credits, go to
                      Settings > Purchase copies and then come back here to
                      print your book</span
                    >
                  </span>
                </div>
              </div>
              <div class="print-confirm-btn">
                <button
                  class="confirm-impression-btn ptr"
                  @click="startConfirmingPrint()"
                  :class="{
                    disabled: copy < 1,
                  }"
                  :disabled="copy < 1"
                >
                  CONFIRM PRINTING
                </button>
                <button
                  class="back-impression-btn ptr"
                  @click="goToPrviewsStep()"
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/navbar.vue"
import axiosInstance from "../axiosInstance.js"
import { serverUrl } from "../severUrl"

export default {
  components: {
    navbar,
  },
  data() {
    return {
      styles: [],
      showingOverlay: true,
      loading: false,
      confirmingPrint: false,
      storiesCount: 0,
      imagesCount: 0,
      pagesCount: 0,
      errorPrint: false,
      successPrint: false,
      errorReason: "",
      errorParagraph: "",
      successParagraph: "",
      currentCoverIndex: "1",
      step: 1,
      country: "",
      adressName: "",
      streetLine1: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      nameError: false,
      streetError: false,
      countryError: false,
      cityError: false,
      zipCodeError: false,
      phoneNumberError: false,
      errorMessage1: "",
      adressSaved: false,
      successSaved: false,
      quantity: "",
      copy: 0,
      printStatus: "writing",
      printpage: false,
      validContract: false,
      popupinfo: true,
    }
  },
  methods: {
    validateStep1: function () {
      this.nameError = ""
      this.streetError = false
      this.countryError = false
      this.cityError = false
      this.zipCodeError = false
      this.phoneNumberError = false
      this.successSaved = false
      this.errorMessage1 = ""
      if (this.adressName === "" || !this.adressName) {
        this.nameError = true
        this.$refs.nameInput.focus()
        this.errorMessage1 = "Address name is required"
        this.step = 1
        return false
      }
      if (this.streetLine1 === "" || !this.streetLine1) {
        this.streetError = true
        this.$refs.streetInput.focus()
        this.errorMessage1 = "Address line 1 is required"
        this.step = 1
        return false
      }
      if (this.country === "" || this.country === "country" || !this.country) {
        this.countryError = true
        this.$refs.countryInput.focus()
        this.errorMessage1 = "Country is required"
        this.step = 1
        return false
      }
      if (this.city === "" || !this.city) {
        this.cityError = true
        this.$refs.cityInput.focus()
        this.errorMessage1 = "City is required"
        this.step = 1
        return false
      }
      if (this.zipCode === "" || !this.zipCode) {
        this.zipCodeError = true
        this.$refs.zipCodeInput.focus()
        this.errorMessage1 = "Zip Code is required"
        this.step = 1
        return false
      }
      if (this.phoneNumber === "" || !this.phoneNumber) {
        this.phoneNumberError = true
        this.$refs.phoneNumberInput.focus()
        this.errorMessage1 = "Phone number is required"
        this.step = 1
        return false
      }

      return true
    },
    goToNextStep: function () {
      if (this.validateStep1() && this.adressSaved) {
        if (
          this.city !== this.order?.city ||
          this.adressName !== this.order?.adressName ||
          this.country !== this.order?.country ||
          this.state !== this.order?.state ||
          this.zipCode !== this.order?.zipCode ||
          this.streetLine1 !== this.order?.streetLine1 ||
          this.phoneNumber !== this.order?.phoneNumber
        ) {
          this.errorMessage1 =
            "You should save your address before proceeding to the next step"
          this.step = 1
          return false
        } else {
          this.errorMessage1 = ""
          this.successSaved = false
          this.step = 2
        }
      } else {
        this.errorMessage1 =
          "You should save your address before proceeding to the next step"
        this.step = 1
        return false
      }
    },
    goToPrviewsStep: function () {
      this.step = 1
    },
    saveShippingAdress: async function () {
      const isvalid = this.validateStep1()

      try {
        if (isvalid) {
          this.order.adressName = this.adressName
          this.order.country = this.country
          this.order.streetLine1 = this.streetLine1
          this.order.city = this.city
          this.order.state = this.state
          this.order.zipCode = this.zipCode
          this.order.phoneNumber = this.phoneNumber

          this.errorMessage1 = ""
          this.successSaved = false
          this.loading = true
          this.showingOverlay = true
          const response = await axiosInstance.put(
            serverUrl + "/api/orders/update-order/",
            this.order,
            {
              withCredentials: true,
            }
          )
          if (response.status == 200) {
            this.adressSaved = true
            this.loading = false
            this.showingOverlay = false
            this.successSaved = true
          }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
        this.showingOverlay = false
        this.adressSaved = false
        this.successSaved = false
      }
    },
    startConfirmingPrint: function () {
      this.confirmingPrint = true
      this.showingOverlay = true
    },
    cancelPrint: function () {
      this.confirmingPrint = false
      this.showingOverlay = false
    },
    confirmContract: function () {
      if (this.validContract) {
        this.showingOverlay = false
        this.printpage = false
      }
    },
    confirmPrint: async function () {
      try {
        this.confirmingPrint = false
        this.loading = true
        this.showingOverlay = true
        const userData = {
          userId: this.user._id,
          bookId: this.order.bookId,
          orderId: this.order._id,
        }
        const response = await axiosInstance.post(
          serverUrl + "/api/orders/confirm-print/",
          userData,
          { withCredentials: true }
        )
        if (response.status == 200) {
          this.order.printStatus = "pending"

          this.loading = false
          this.printStatus = "pending"
          this.showingOverlay = true
          this.successPrint = true
          this.successParagraph =
            "Thank you! Your print request is confirmed. Expect a tracking link in your email within 7 days."
        }
      } catch (error) {
        console.log(error)
        this.loading = false
        this.showingOverlay = false
        if (error.response.status == 400) {
          console.log(error.response.data)
          this.showingOverlay = true
          this.errorPrint = true
          this.errorReason = error.response.data.reason
          this.errorParagraph = error.response.data.paragraph
        }
      }
    },
    hideErrorPanel: function () {
      this.errorPrint = false
      this.showingOverlay = false
      this.errorReason = ""
      this.errorParagraph = ""
    },
    hideSuccessPanel: function () {
      this.successPrint = false
      this.showingOverlay = false
      this.successParagraph = ""
    },
  },
  async mounted() {
    try {
      if (this.order) {
        this.showingOverlay = true
        this.loading = true
        const bookId = this.order?.bookId
        const result = await axiosInstance.get(
          serverUrl + "/api/books/stats/" + bookId,
          { withCredentials: true }
        )
        this.pagesCount = result.data.pagesCount

        this.imagesCount = result.data.imagesCount
        this.storiesCount = result.data.storiesCount
        this.showingOverlay = false
        this.loading = false
      }

      this.showingOverlay = true
      this.printpage = true
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    order: function () {
      return this.$store.getters.getOrder
    },
  },
  created() {
    this.adressName = this.order?.adressName
    this.country = this.order?.country ? this.order?.country : "country"
    this.streetLine1 = this.order?.streetLine1
    this.city = this.order?.city
    this.state = this.order?.state
    this.zipCode = this.order?.zipCode
    this.phoneNumber = this.order?.phoneNumber
    this.quantity = this?.order?.quantity ? this?.order?.quantity : 1
    this.copy = this?.order?.copy
    console.log("this.copy, ", this.copy)
    if (this.order?.streetLine1) {
      this.adressSaved = true
    }
    this.printStatus = this.order.printStatus
  },
}
</script>

<style>
.main-section-container {
  padding: 40px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 22px;
  display: flex;
}
.route-section {
  overflow: scroll;
}
.confirm-impression-btn {
  background: #e1504b;
  width: 100%;
  padding: 20px 0px;
  font-family: PT-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  border: none;

  /* white/white */
  color: #ffffff;
}
.back-impression-btn {
  margin-top: 10px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  width: 100%;
  padding: 20px 0px;
  font-family: PT-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
}

.confirm-impression-btn.disabled {
  background: #bebebe;
  cursor: not-allowed;
}

.left-side-print {
  margin-right: 53px;
}

.right-side-print {
  width: 293px;
}

.print-confirm-btn {
  text-align: center;
}

.print-confirm-btn-mobile {
  display: none;
  margin-bottom: 14px;
}

.print-paragraph {
  font-family: galaxie-polaris;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #062a20;
}
.form-input-error:focus {
  border: 2px solid #eb4848;
}
.print-image {
  height: 324px;
  width: 213px;
}

.book-stats-container {
  font-weight: 500;
  font-size: 16px;
  color: #062a20;
  margin-bottom: 52px;
}

.route-title-container {
  display: none;
}

.print-section-container {
  padding: 70px;
  background: #ffffff;
  border: 1px solid rgba(6, 42, 32, 0.2);
  border-radius: 22px;
  margin-top: 44px;
  box-sizing: border-box;
  width: 468px;
  max-width: 100%;
}
.print-input {
  padding: 15px 20px 18px 20px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(6, 42, 32, 0.2);
  font-family: galaxie-polaris;
  outline: none;
  color: #14473c;
  height: 62px;
  width: 100%;
  box-sizing: border-box;
}
select.print-input {
  display: block;
  font-size: 1em;
  /* padding: 0.8rem 0.5rem; */
  font-family: inherit;
  appearance: none;
  background-image: url("../assets/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.9em;
}
.print-input:focus {
  border: 1px solid rgba(6, 42, 32, 0.35);
}
.print-input::placeholder {
  color: rgba(6, 42, 32, 0.4);
}
.print-form-container {
  padding: 70px;
  background: #ffffff;
  border: 1px solid rgba(6, 42, 32, 0.2);
  border-radius: 22px;
  margin-top: 44px;
  box-sizing: border-box;
  width: 468px;
  max-width: 100%;
}
.form-title-container {
  font-family: galaxie-polaris;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  color: #062a20;
}
.print-form-section {
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
}
.login-btn {
  background: #14473c;
  padding: 20px 43px;
  color: white;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  width: 100%;
  font-family: PT-serif;
  font-weight: bold;
  cursor: pointer;
}
.print-form-error {
  color: #eb4848;
  font-size: 14px;
}
.print-form-success {
  color: #44ba12;
  font-size: 14px;
}
.popup-info-container {
  position: relative;
}
.popup-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background: rgba(255, 184, 0, 1);
  color: black;
  position: absolute;
  border-radius: 22px;
  top: 0px;
  left: 0px;
  font-size: 18px;
  font-family: "galaxie-polaris";
  font-weight: 500;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .print-section-container {
    padding: 0px;
    border: none;
    margin-top: 50px;
    background: transparent;
  }
  .route-section {
    overflow: scroll;
    padding: 24px;
  }
  .main-section-container {
    padding: 30px 20px;

    flex-direction: column;
  }
  .left-side-print {
    margin-right: 0px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-side-print {
    width: 100%;
  }
  .print-confirm-btn-mobile {
    display: block;
  }
  .print-confirm-btn {
    display: none;
  }
  .confirm-impression-btn {
    font-size: 14px;
    padding: 15px 0px;
  }
  .print-paragraph {
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    max-width: 500px;
  }
  .print-image {
    width: 173px;
    height: 264px;
  }

  .book-stats-container {
    font-weight: 500;
    font-size: 14px;
    color: #062a20;
  }

  .route-title-container {
    display: block;
    font-size: 20px;
    font-weight: 500;
  }
  .book-stats-container {
    margin-bottom: 18px;
  }
  .overlay {
    /* Your existing overlay styles */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Semi-transparent overlay background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
  }
  .pop-up {
    max-height: 100vh; /* Set a maximum height for the pop-up to make it scrollable */
    overflow-y: auto; /* Enable vertical scrolling */
  }
}
</style>
