<template>
  <div class="d-row site-background">
    <div class="overlay" v-if="showingOverlay"></div>
    <navbar></navbar>
    <div class="container" style="height: 100%">
      <div class="route-section d-col preview">
        <div class="route-title-container">
          <span class="route-title title-section">Review content</span>
        </div>
        <div class="preview-container">
          <div class="d-flex-centered" style="height: 100%">
            <pdf v-if="pdfFile"></pdf>
            <div
              v-if="!pdfFile"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
              "
            >
              <div class="lds-dual-ring"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateBook } from "../interiorGeneration"
import { serverUrl } from "../severUrl"
import axiosInstance from "../axiosInstance.js"
import navbar from "../components/navbar.vue"
// import pdfobject from "pdfobject";
import pdf from "../components/PDFJSViewer.vue"

export default {
  components: {
    navbar,
    pdf,
  },
  data() {
    return {
      isLoadingPdf: false,
      showingOverlay: false,
      listOrders: [],
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    order: function () {
      return this.$store.getters.getOrder
    },
    pdfFile: function () {
      return this.$store.getters.getPdfFile
    },
  },
  methods: {
    applyContent: async function () {
      try {
        const bookId = this.order?.bookId
        const result = await axiosInstance.get(
          serverUrl +
            "/api/books/get-book-with-pictures/" +
            bookId +
            "?populated=true",
          { withCredentials: true }
        )
        const ornament = await fetch(serverUrl + "/book-ornament.jpg").then(
          (res) => res.arrayBuffer()
        )
        const bookStyles = { ornament }
        const book = JSON.parse(JSON.stringify(result.data))
        const resultBookStats = await axiosInstance.get(
          serverUrl + "/api/books/stats/" + bookId,
          { withCredentials: true }
        )
        const pagesCount = resultBookStats.data.pagesCount
        book.pagesCount = pagesCount
        const buffer = await generateBook(book, bookStyles)
        const blob = new Blob([buffer], {
          type: "application/pdf",
        })
        const pdfFile = URL.createObjectURL(blob)
        this.$store.commit("setPdfFile", pdfFile)
      } catch (error) {
        console.log(error)
      }
    },
    redirectToProfile: function () {
      this.$router.push("/profile")
    },
    setdefaultBook: async function (selectedBook) {
      this.user.defaultBookId = selectedBook
      const response = await axiosInstance.put(
        serverUrl + "/api/users/",
        this.user,
        {
          withCredentials: true,
        }
      )
      if (response.status == 200) {
        this.$store.commit("setUser", response.data)
        await this.$store.dispatch("fetchPopulatedChapters")

        console.log(response.data)
      }
      if (this.$route.path === "/") {
        // check if current path is '/'
        window.location.reload() // refresh the page
      } else {
        this.$router.push("/") // navigate to '/'
      }
    },
  },
  async mounted() {
    try {
      await this.applyContent()
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style>
.preview-container {
  background: white;
  padding: 79px 160px 30px 160px;
  border: 1px solid rgba(6, 42, 32, 0.2);
  box-sizing: border-box;
  border-radius: 30px;
  flex-grow: 1;
}
.route-section.preview {
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .preview-container {
    background: transparent;
    padding: 0px;
    border: none;
    border-radius: 0px;
  }
  .site-background.preview {
    height: calc(100vh - calc(100vh - 100%));
  }
  .route-title-container {
    margin-top: 0px;
  }
}
</style>
