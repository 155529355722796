<template>
  <div class="d-row site-background admin">
    <div class="overlay" v-if="showingOverlay">
      <div class="pop-up d-flex-centered d-col" v-if="isDeletingUser">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Voulez-vous supprimer l'utilisateur ?</span>
        </div>
        <div class="pop-up-buttons-container">
          <button
            class="pop-up-btn confirm-admin"
            @click="deleteUser(deletingUser)"
          >
            OUI
          </button>
          <button class="pop-up-btn" @click="cancelDeletingUser()">NON</button>
        </div>
      </div>
      <div v-if="loading">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <navbar admin="true"></navbar>
    <div class="container">
      <div class="route-section">
        <div style="margin-left: 12px">
          <div
            class="d-row"
            style="
              justify-content: space-between;
              margin-top: 21px;
              align-items: center;
            "
          >
            <div class="d-row">
              <div
                class="panel-tab-btn"
                :class="{ active: panelIndex == 0 }"
                @click="filterTable(0)"
              >
                Printing Requests
              </div>
              <div
                class="panel-tab-btn"
                :class="{ active: panelIndex == 1 }"
                @click="filterTable(1)"
              >
                Printing in progress
              </div>
              <div
                class="panel-tab-btn"
                :class="{ active: panelIndex == 3 }"
                @click="filterTable(3)"
              >
                Shipped
              </div>
              <div
                class="panel-tab-btn"
                :class="{ active: panelIndex == 2 }"
                @click="filterTable(2)"
              >
                Book in progress
              </div>
            </div>
            <div
              class="download-all-btn d-flex-centered ptr"
              @click="downloadAudit()"
            >
              <span style="display: inline-block; margin-right: 12px"
                >DOWNLOAD ALL</span
              >
              <img src="../assets/download-icon.svg" alt="" />
            </div>
          </div>
          <div
            style="
              margin-top: 18px;
              background: #ffffff;
              border: 1px solid rgba(6, 42, 32, 0.2);
              box-sizing: border-box;
              border-radius: 30px;
              height: 650px;
              overflow-y: auto;
              padding: 40px 27px;
            "
          >
            <div class="example-header" style="margin-bottom: 10px">
              Page Size:
              <select v-on:change="onPageSizeChanged()" id="page-size">
                <option
                  value="50"
                  :selected="gridOptions.paginationPageSize == 50"
                >
                  50
                </option>
                <option
                  value="100"
                  :selected="gridOptions.paginationPageSize == 100"
                >
                  100
                </option>
                <option
                  value="500"
                  :selected="gridOptions.paginationPageSize == 500"
                >
                  500
                </option>
                <option
                  value="1000"
                  :selected="gridOptions.paginationPageSize == 1000"
                >
                  1000
                </option>
              </select>
            </div>
            <!--
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-alpine"
              :gridOptions="gridOptions"
              :rowData="data"
              :columnDefs="columnDefs"
              @grid-ready="onFirstDataRendered"
              :defaultColDef="defaultColDef"
            >
            </ag-grid-vue>
          -->

            <h2 style="margin-bottom: 10px; margin-top: 25px">
              Order With New Printer
            </h2>
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-alpine"
              :gridOptions="printGridOptions"
              :rowData="printOrderData"
              :columnDefs="printOrderColumnDefs"
              @grid-ready="onFirstDataRendered"
              :defaultColDef="printDefaultColDef"
            >
            </ag-grid-vue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/navbar.vue"
import { serverUrl } from "../severUrl"
//import axios from "axios"
import { AgGridVue } from "ag-grid-vue"
import "ag-grid-community/styles//ag-grid.css"
import "ag-grid-community/styles//ag-theme-alpine.css"
import axiosInstance from "../axiosInstance.js"
export default {
  watch: {},
  components: {
    navbar,
    AgGridVue,
  },
  data() {
    return {
      panelIndex: 0,
      usersData: [],
      showingOverlay: false,
      loading: false,
      deletingUserId: "",
      isDeletingUser: false,
      currentPage: 0,
      data: [],
      printOrderData: [],
      defaultColDef: {
        sortable: true,
        flex: 1,
        filter: true,
        resizable: true,
      },
      gridApi: null,
      columnApi: null,
      gridPrintApi: null,
      columnPrintApi: null,
      gridOptions: {
        pagination: true,
        paginationPageSize: 100, // Nombre d'éléments par page
        onFirstDataRendered: this.onFirstDataRendered,
      },
      printGridOptions: {
        pagination: true,
        paginationPageSize: 100, // Nombre d'éléments par page
        onFirstDataRendered: this.onFirstDataPrintRendered,
      },
      printDefaultColDef: {
        sortable: true,
        flex: 1,
        filter: true,
        resizable: true,
      },
      domLayout: "autoHeight",
      columnDefs: [
        {
          headerName: "Order Number",
          field: "orderNumber",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Order ID",
          field: "orderId",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        { headerName: "Date", field: "printDate" },
        { headerName: "Tracking", field: "tracking", hide: true },
        {
          headerName: "Gift Recipient’s email",
          field: "giftEmail",
          cellRenderer: (params) => {
            if (params.value) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Gift buyer email",
          field: "email",
          cellRenderer: (params) => {
            // put the value in bold
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Shipping Address",
          field: "adressName",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        { headerName: "Quantity", field: "quantity" },
        { headerName: "Audiobook", field: "audioBook" },
        {
          headerName: "Actions",
          field: "action",
          cellRenderer: (params) => {
            const actionsDiv = document.createElement("div")
            actionsDiv.classList.add("action-buttons-container")

            // Bouton pour télécharger les fichiers de l'utilisateur avec SVG
            const downloadButton = document.createElement("button")
            downloadButton.innerHTML = `
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6942_3851)">
                <path d="M12 14.25V3.75" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.75 10.5L12 14.25L8.25 10.5" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_6942_3851">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            `
            downloadButton.addEventListener("click", () =>
              this.downloadUserFiles(
                params.value?.bookId,
                params.value?.orderNumber
              )
            )

            // Bouton pour supprimer l'utilisateur avec SVG
            const deleteButton = document.createElement("button")
            deleteButton.innerHTML = `
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6942_3856)">
                <path d="M18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21Z" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.25 3V8.25H19.5" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.75 12L14.25 16.5" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.25 12L9.75 16.5" stroke="#9BAAA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_6942_3856">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            `
            deleteButton.addEventListener("click", () =>
              this.startDeletingUser(params.value?._id)
            )
            // Bouton pour mettre à jour le statut de l'utilisateur avec SVG
            const updateStatusButton = document.createElement("button")
            updateStatusButton.innerHTML = `
              <svg width="24" height="24"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 32 32" xml:space="preserve">
              <g>
                <g id="spin">
                  <g>
                    <path style="fill:#9BAAA6;" d="M25.883,6.086l-2.82,2.832C24.953,10.809,26,13.324,26,16c0,5.516-4.484,10-10,10v-2l-4,4l4,4v-2
                      c7.719,0,14-6.281,14-14C30,12.254,28.539,8.734,25.883,6.086z"/>
                    <path style="fill:#9BAAA6;" d="M20,4l-4-4v2C8.281,2,2,8.281,2,16c0,3.746,1.461,7.266,4.117,9.914l2.82-2.832
                      C7.047,21.191,6,18.676,6,16c0-5.516,4.484-10,10-10v2L20,4z"/>
                  </g>
                </g>
              </g>
              </svg>
            `
            updateStatusButton.addEventListener("click", () =>
              this.updateStatus(params.value)
            )

            // Ajouter les boutons à la div des actions

            actionsDiv.appendChild(downloadButton)
            actionsDiv.appendChild(deleteButton)
            if (this.status !== "done" && this.status !== "writing")
              actionsDiv.appendChild(updateStatusButton)

            return actionsDiv
          },
        },
      ],
      printOrderColumnDefs: [
        {
          headerName: "Original Order Id",
          field: "originalOrderId",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Order ID",
          field: "orderId",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Order Number",
          field: "orderNumber",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        { headerName: "Creation Print Date", field: "creationDate" },
        { headerName: "StartDate", field: "startDate" },

        {
          headerName: "Email",
          field: "email",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Tracking",
          field: "trackingLink",
          cellRenderer: (params) => {
            if (params.value) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  <a href ="${params?.value}" target="_blank"> Tracking </a>
            `
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Shipping Address",
          field: "adressName",
          cellRenderer: (params) => {
            if (params.value !== "") {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  ${params?.value}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6942_3613)">
                      <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 3.75H20.25V17.25" stroke="#062A20" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_6942_3613">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            `
              this.addClickEventListener(div, params.value)
              return div
            } else {
              return ""
            }
          },
        },
        { headerName: "Quantity", field: "quantity" },
        { headerName: "Page Count", field: "pageCount" },
        { headerName: "Sent To Printer", field: "isSentToPrint" },
        {
          headerName: "Download Pages",
          field: "urlPages",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  <a href ="${params?.value}" target="_blank"> Pages </a>
            `
              return div
            } else {
              return ""
            }
          },
        },
        {
          headerName: "Download Cover",
          field: "urlCover",
          cellRenderer: (params) => {
            if (params.value !== null) {
              // put the value in bold
              const div = document.createElement("div")
              div.classList.add("copy-icon-container")
              div.innerHTML = `
                  <a href ="${params?.value}" target="_blank"> Cover </a>
            `
              return div
            } else {
              return ""
            }
          },
        },
      ],
      status: "pending",
      isFetchingData: false,
    }
  },

  methods: {
    cancelDeletingUser: function () {
      this.showingOverlay = false
      this.isDeletingUser = false
      this.deletingUserId = ""
    },
    startDeletingUser: function (id) {
      console.log("startDeletingUser", id)
      this.showingOverlay = true
      this.isDeletingUser = true
      this.deletingUserId = id
    },
    deleteUser: async function () {
      try {
        this.showingOverlay = true
        this.loading = true

        await axiosInstance.delete(
          serverUrl + "/api/users/" + this.deletingUserId,
          {
            withCredentials: true,
          }
        )
        //await this.fetchUsersData()
        this.showingOverlay = false
        this.loading = false
      } catch (error) {
        console.log(error)
        this.showingOverlay = false
        this.loading = false
      }
    },
    filterTable: async function (index) {
      this.panelIndex = index
      await this.filteredUsersData()
    },
    downloadAudit: function () {
      try {
        window.open(serverUrl + "/api/users/downloadcsv")
      } catch (error) {
        console.log(error)
      }
    },
    downloadUserFiles: function (bookId, orderNumber) {
      window.open(serverUrl + "/api/users/files/" + bookId + "/" + orderNumber)
    },
    updateStatus: async function (order) {
      try {
        const newStatus = this.status == "pending" ? "printing" : "done"
        const response = await axiosInstance.put(
          serverUrl + "/api/users/update-status",
          { status: newStatus, id: order._id, orderId: order.orderId },
          { withCredentials: true }
        )
        if (response.status == 200) {
          order.printStatus = newStatus
        }
      } catch (error) {
        console.log(error)
        alert(
          "An error occured while updating user " +
            order.orderId +
            "Please refresh the page and try again"
        )
      }
    },
    onPageSizeChanged: function () {
      const value = document.getElementById("page-size").value
      this.gridApi.paginationSetPageSize(Number(value))
    },
    fetchUsersData: async function () {
      if (this.isFetchingData) {
        return
      }
      try {
        this.isFetchingData = true
        this.loading = true
        this.showingOverlay = true
        const response = await axiosInstance.get(
          serverUrl + `/api/users/get-list-orders/${this.status}`,
          {
            withCredentials: true,
          }
        )
        // Récupérez les données brutes de la réponse
        const { orders } = response.data
        // Mise à jour des données de la grille

        this.data = orders
      } catch (error) {
        console.log(error)
      } finally {
        this.isFetchingData = false
        this.loading = false
        this.showingOverlay = false
      }
    },
    fetchPrintUsersData: async function () {
      if (this.isFetchingData) {
        return
      }
      try {
        this.isFetchingData = true
        this.loading = true
        this.showingOverlay = true
        const response2 = await axiosInstance.get(
          serverUrl + `/api/users/get-list-print-orders/${this.status}`,
          {
            withCredentials: true,
          }
        )
        // Récupérez les données brutes de la réponse
        const { ordersPrint } = response2.data
        // Mise à jour des données de la grille

        this.printOrderData = ordersPrint
        // Stockez les données transformées dans rowData
        // Mettez à jour la configuration de la grille
      } catch (error) {
        console.log(error)
      } finally {
        this.isFetchingData = false
        this.loading = false
        this.showingOverlay = false
      }
    },
    filteredUsersData: async function () {
      if (this.panelIndex == 0) {
        this.status = "pending"
        this.columnApi.setColumnVisible("tracking", false)

        // await this.fetchUsersData()
        await this.fetchPrintUsersData()
      }
      if (this.panelIndex == 1) {
        this.status = "printing"
        //await this.fetchUsersData()
        await this.fetchPrintUsersData()
        this.columnApi.setColumnVisible("tracking", false)
      }
      if (this.panelIndex == 2) {
        this.status = "writing"
        // await this.fetchUsersData()
        await this.fetchPrintUsersData()
        this.columnApi.setColumnVisible("tracking", false)
      }
      if (this.panelIndex == 3) {
        this.status = "done"
        //await this.fetchUsersData()
        await this.fetchPrintUsersData()
        this.columnApi.setColumnVisible("tracking", true)
      }
    },
    onFirstDataRendered: async function (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      //await this.fetchUsersData()
    },
    onFirstDataPrintRendered: async function (params) {
      this.gridPrintApi = params.api
      this.columnPrintApi = params.columnApi
      await this.fetchPrintUsersData()
    },
    copyToClipboard(value) {
      console.log("value", value)
      // Create a temporary textarea element
      const textarea = document.createElement("textarea")
      textarea.value = value
      document.body.appendChild(textarea)

      // Select the text in the textarea
      textarea.select()
      textarea.setSelectionRange(0, 99999) // For mobile devices

      try {
        // Use the Clipboard API to copy the selected text to the clipboard
        document.execCommand("copy")
      } catch (err) {
        console.error("Unable to copy to clipboard", err)
      } finally {
        // Remove the temporary textarea element
        document.body.removeChild(textarea)
      }
    },
    addClickEventListener(element, value) {
      element.addEventListener("click", () => this.copyToClipboard(value))
    },
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
  },
}
</script>

<style>
.site-background.admin {
  background: #14473c;
}
.panel-tab-btn {
  border-radius: 20px;
  padding: 20px 23px;
  color: #14473c;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  margin-right: 10px;
}
.panel-tab-btn:hover {
  cursor: pointer;
}
.panel-tab-btn.active {
  background: #14473c;
  color: white;
  font-weight: 500;
}
.download-all-btn {
  font-family: PT-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: white;
  width: 168px;
  height: 44px;
  background: #0f172a;
  border-radius: 20px;
}

.table-title-row {
  margin-bottom: 25px;
}
.table-title-element {
  margin-right: 50px;
  text-align: center;
  width: 150px;
  font-size: 16px;
  line-height: 17px;
  color: #14473c;
}
.table-row {
  margin-bottom: 8px;
  background: #ffffff;
  border: 1px solid rgba(6, 42, 32, 0.1);
  box-sizing: border-box;
  border-radius: 18px;
  height: 94px;
  align-items: center;
}
.table-select {
  border: 1px solid rgba(6, 42, 32, 0.1);
  box-sizing: border-box;
  border-radius: 40px;
  width: 134px;
  height: 44px;
  background: white;
  padding: 0px 10px;
  font-family: galaxie-polaris;
  font-weight: 300;
}

.table-select-option {
  width: 134px;
  height: 44px;
  background: white;
  padding: 10px 10px;
  font-family: galaxie-polaris;
  font-weight: 300;
}
.confirm-admin {
  background: #0f172a;
  color: white;
  margin-right: 30px;
  border: none;
}
.tooltip {
  position: relative;
  display: inline-block;
  font-size: 11px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px 10px;
  font-size: 13px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.action-buttons-container button {
  margin-right: 5px;
  border: none;
  background: none;
  cursor: pointer;
}
</style>
