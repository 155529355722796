<template>
  <div style="margin-top: 10px" class="audio-recorder">
    <!--- <button
      @click="addPunctuation"
      style="background: transparent; border: none; vertical-align: middle"
    >
      Add Ponctuation
    </button>-->
    <div v-if="!isListening">
      <select
        v-if="btn"
        v-model="selected"
        @change="handleLanguageChange"
        class="language"
      >
        <option v-for="item in items" :key="item.value" :value="item.value">
          {{ item.text }}
        </option>
      </select>
      <button
        @click="toggleListening"
        style="background: transparent; border: none; vertical-align: middle"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#062A20" fill-opacity="0.1" />
          <g clip-path="url(#clip0_6427_1499)">
            <path
              d="M13 16V12C13 11.2044 13.3161 10.4413 13.8787 9.87868C14.4413 9.31607 15.2044 9 16 9C16.7956 9 17.5587 9.31607 18.1213 9.87868C18.6839 10.4413 19 11.2044 19 12V16C19 16.7956 18.6839 17.5587 18.1213 18.1213C17.5587 18.6839 16.7956 19 16 19C15.2044 19 14.4413 18.6839 13.8787 18.1213C13.3161 17.5587 13 16.7956 13 16ZM21 16C21 15.8674 20.9473 15.7402 20.8536 15.6464C20.7598 15.5527 20.6326 15.5 20.5 15.5C20.3674 15.5 20.2402 15.5527 20.1464 15.6464C20.0527 15.7402 20 15.8674 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16C12 15.8674 11.9473 15.7402 11.8536 15.6464C11.7598 15.5527 11.6326 15.5 11.5 15.5C11.3674 15.5 11.2402 15.5527 11.1464 15.6464C11.0527 15.7402 11 15.8674 11 16C11.0015 17.2391 11.4625 18.4336 12.2937 19.3525C13.1249 20.2715 14.2673 20.8496 15.5 20.975V22.5C15.5 22.6326 15.5527 22.7598 15.6464 22.8536C15.7402 22.9473 15.8674 23 16 23C16.1326 23 16.2598 22.9473 16.3536 22.8536C16.4473 22.7598 16.5 22.6326 16.5 22.5V20.975C17.7327 20.8496 18.8751 20.2715 19.7063 19.3525C20.5375 18.4336 20.9985 17.2391 21 16Z"
              fill="#14473C"
            />
          </g>
          <defs>
            <clipPath id="clip0_6427_1499">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div v-if="isListening" class="listening">
      <button
        @click="toggleListening"
        style="background: transparent; border: none; margin-right: 10px"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6427_1700)">
            <path
              d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H13C12.7348 20 12.4804 19.8946 12.2929 19.7071C12.1054 19.5196 12 19.2652 12 19V13C12 12.7348 12.1054 12.4804 12.2929 12.2929C12.4804 12.1054 12.7348 12 13 12H19C19.2652 12 19.5196 12.1054 19.7071 12.2929C19.8946 12.4804 20 12.7348 20 13V19Z"
              fill="#E1504B"
            />
          </g>
          <defs>
            <clipPath id="clip0_6427_1700">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        @click="continusListening"
        style="background: transparent; border: none; margin-right: 10px"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#062A20" fill-opacity="0.1" />
          <circle cx="16" cy="16" r="14" fill="#062A20" fill-opacity="0.4" />
          <circle cx="16" cy="16" r="12" fill="#14473C" />
          <g clip-path="url(#clip0_6427_1708)">
            <path
              d="M13 16V12C13 11.2044 13.3161 10.4413 13.8787 9.87868C14.4413 9.31607 15.2044 9 16 9C16.7956 9 17.5587 9.31607 18.1213 9.87868C18.6839 10.4413 19 11.2044 19 12V16C19 16.7956 18.6839 17.5587 18.1213 18.1213C17.5587 18.6839 16.7956 19 16 19C15.2044 19 14.4413 18.6839 13.8787 18.1213C13.3161 17.5587 13 16.7956 13 16ZM21 16C21 15.8674 20.9473 15.7402 20.8536 15.6464C20.7598 15.5527 20.6326 15.5 20.5 15.5C20.3674 15.5 20.2402 15.5527 20.1464 15.6464C20.0527 15.7402 20 15.8674 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16C12 15.8674 11.9473 15.7402 11.8536 15.6464C11.7598 15.5527 11.6326 15.5 11.5 15.5C11.3674 15.5 11.2402 15.5527 11.1464 15.6464C11.0527 15.7402 11 15.8674 11 16C11.0015 17.2391 11.4625 18.4336 12.2937 19.3525C13.1249 20.2715 14.2673 20.8496 15.5 20.975V22.5C15.5 22.6326 15.5527 22.7598 15.6464 22.8536C15.7402 22.9473 15.8674 23 16 23C16.1326 23 16.2598 22.9473 16.3536 22.8536C16.4473 22.7598 16.5 22.6326 16.5 22.5V20.975C17.7327 20.8496 18.8751 20.2715 19.7063 19.3525C20.5375 18.4336 20.9985 17.2391 21 16Z"
              fill="#F6F4F3"
            />
          </g>
          <defs>
            <clipPath id="clip0_6427_1708">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
//import natural from "natural"
//import axios from "axios"
import isMobile from "is-mobile"
export default {
  watch: {},
  data() {
    return {
      btn: true,
      btnStop: false,
      result: false,
      resultError: false,
      textResult: "",
      selected: "en-US",
      audioContext: "",
      punctuationText: "",
      isMobile: isMobile(),
      model: "",
      scriptNode: "",
      items: [
        {
          text: "English (AU)",
          value: "en-AU",
        },
        {
          text: "English (US)",
          value: "en-US",
        },
        {
          text: "English (UK)",
          value: "en-EN",
        },
      ],
      isListening: false,
      mic: "",
      nlp: "",
    }
  },
  methods: {
    startRecording() {
      if (this.isListening) {
        this.mic.start()
        this.$emit("start-recorder")
      } else {
        this.mic.stop()
        this.$emit("stop-recorder")
      }
    },
    toggleListening() {
      this.isListening = !this.isListening
      this.startRecording()
    },
    handleLanguageChange() {
      this.mic.lang = this.selected
    },
    pauseListening() {
      //this.mic.paus()
      console.log("pause")
      if (this.isListening) {
        this.mic.abort() // Mettre le microphone en pause
        this.note = ""
        this.$emit("pause-recorder")
      }
    },
    continusListening() {
      this.mic.start()
      this.isListening = true
    },

    /*async addPunctuation() {
      // Remplacez 'YOUR_API_SECRET_KEY' par votre clé secrète ChatGPT
      const apiKey = process.env.VUE_APP_CHAT_GPT_API_KEY
      const apiUrl = process.env.VUE_APP_CHAT_GPT_API_URL
      console.log("apiUrl", apiUrl)
      console.log("apiKey", apiKey)
      try {
        const response = await axios.post(
          apiUrl,
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You are a helpful assistant that adds punctuation to text.",
              },
              {
                role: "user",
                content: this.note,
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
          }
        )

        this.punctuationText = response.data.choices[0].message.content
        this.$emit("update-transcription", this.punctuationText)
      } catch (error) {
        console.error("Error adding punctuation:", error)
        this.punctuationText =
          "Une erreur s'est produite lors de l'ajout de la ponctuation."
      }
    }
,
    addPunctuationToTranscription(transcription) {
      // Créez un modèle de segmentation de phrases
      const tokenizer = new natural.SentenceTokenizer()

      // Divisez la transcription en phrases
      const sentences = tokenizer.tokenize(transcription)

      // Ajouter des ponctuations aux phrases
      const punctuatedText = sentences
        .map((sentence) => {
          if (sentence.trim().charAt(sentence.length - 1) !== ".") {
            return sentence.trim() + "."
          } else {
            return sentence.trim()
          }
        })
        .join(" ")

      return punctuatedText
    },*/
    async initMicro() {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition
      this.mic = new SpeechRecognition()
      this.mic.continuous = true
      this.mic.interimResults = true
      this.mic.lang = this.selected

      this.mic.onstart = () => {
        this.note = "" // Reset the transcription when the mic starts
      }

      this.mic.onresult = (event) => {
        const results = Array.from(event.results)
        // const lastResult = results[results.length - 1]
        //const finalResult = results.find((result) => result.isFinal)

        // Reset the transcription whenever you receive new results
        this.note = results
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join(" ")
        this.note = this.note.replace(/\s{2,}/g, " ")
        /*  if (this.isMobile) {
          if (finalResult) {
            this.$emit(
              "update-transcription",
              finalResult[0]?.transcript?.replace(/\s{2,}/g, " ")
            )
          }
        } else {
          this.$emit("update-transcription", this.note)
        }*/
        this.$emit("update-transcription", this.note)
        this.mic.onerror = (event) => {
          console.log(event.error)
        }
      }
    },
  },
  async mounted() {
    await this.initMicro()
  },
}
</script>

<style>
.language {
  border: none;
  font-size: 18px;
  vertical-align: middle;
  padding: 5px;
  margin-right: 10px;
  font-weight: bold;
  font-family: "PT-serif";
}
</style>
