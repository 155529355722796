<template>
  <div class="d-row site-background admin">
    <div class="overlay" v-if="showingOverlay">
      <div class="pop-up d-flex-centered d-col" v-if="isDeletingOrder">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Are you sure you want to disactivate this order ?</span>
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="deleteAccount()">
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="isSendingGiftEmail">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Are you sure you want to send a gift email ?</span>
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="sendGiftEmail()">
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="isSendingRefund">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Are you sure you want to refund this paiement</span>
        </div>
        <div class="d-row recreacte-row">
          <span
            >Refund amount (leave blank to refund the total order amount)</span
          >
        </div>
        <br />

        <div class="d-row recreacte-row">
          <input
            type="number"
            name="amountToRefund"
            v-model="amountToRefund"
            class="recreate-form-input"
          />
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="refund()">
            Confirm
          </button>
          <button class="pop-up-btn" @click="cancel()">Cancel</button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="isSendingLastQuestion">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span
            >Are you sure you want to send the last scheduled question to the
            recipient ?</span
          >
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="sendLastScudeledQ()">
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>

      <div class="pop-up d-flex-centered d-col" v-if="isToIwill">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Are you sure you want to transform the order to I will </span>
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="transformToIWill()">
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="isToSomeElsewill">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span
            >Are you sure you want to transform the order to Some Else will
          </span>
          <div class="d-row recreacte-row">
            <input
              type="email"
              name="emailGuest"
              v-model="emailGuest"
              placeholder="new reciptient email"
              class="recreate-form-input"
              required
            />
          </div>
          <div class="d-row recreacte-row">
            <input
              type="text"
              name="firstNameGuest"
              v-model="firstNameGuest"
              placeholder="new reciptient First Name"
              class="recreate-form-input"
              required
            />
          </div>
          <div class="d-row recreacte-row">
            <input
              type="text"
              name="lastNameGuest"
              v-model="lastNameGuest"
              placeholder="new reciptient Last Name"
              class="recreate-form-input"
              required
            />
          </div>
          <div class="d-row recreacte-row">
            <input
              type="text"
              name="giftSenderBuyer"
              v-model="giftSenderBuyer"
              placeholder="new gift sender"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <textarea
              rows="4"
              style="box-sizing: border-box"
              class="question-title-textarea recreate-form-textarea"
              v-model="giftMessageBuyer"
            />
          </div>
          <div class="d-row recreacte-row">
            <datepicker
              :disabled-dates="state.disabledDates"
              input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
              calendar-class="register-calendar"
              v-model="giftDateGuest"
              name="giftDateGuest"
              ref="recipGiftDateInput"
              format="MM/dd/yyyy"
              placeholder="Gift Date"
            ></datepicker>
          </div>
        </div>
        <div class="pop-up-buttons-container">
          <button
            class="pop-up-btn confirm-admin"
            @click="transformToSomeElseWill()"
          >
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>

      <div class="pop-up d-flex-centered d-col" v-if="isResetQuestion">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <span>Are you sure you want to reset questions ?</span>
        </div>
        <div class="pop-up-buttons-container">
          <button class="pop-up-btn confirm-admin" @click="resetQuestion()">
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>
      <div class="pop-up d-flex-centered d-col" v-if="isRecreateAccount">
        <div class="pop-up-title">
          <span>Confirmation</span>
        </div>
        <div class="pop-up-paragraph">
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedEBTR" />
            <span v-if="checkedEBTR">keep default buyer email </span>
            <input
              v-if="!checkedEBTR"
              type="email"
              name="emailBuyerTR"
              v-model="emailBuyerTR"
              placeholder="new buyer email"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedERTR" />
            <span v-if="checkedERTR">keep default reciptient email </span>
            <input
              v-if="!checkedERTR"
              type="email"
              name="emailReciptientTR"
              v-model="emailReciptientTR"
              placeholder="new reciptient email"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedFNRTR" />
            <span v-if="checkedFNRTR">keep default reciptient First Name </span>
            <input
              v-if="!checkedFNRTR"
              type="text"
              name="fNameReciptientTR"
              v-model="fNameReciptientTR"
              placeholder="new reciptient First Name"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedLNRTR" />
            <span v-if="checkedLNRTR">keep default reciptient Last Name </span>
            <input
              v-if="!checkedLNRTR"
              type="text"
              name="lNameReciptientTR"
              v-model="lNameReciptientTR"
              placeholder="new reciptient Last Name"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedgfTR" />
            <span v-if="checkedgfTR">keep default gift sender </span>
            <input
              v-if="!checkedgfTR"
              type="text"
              name="giftSenderTR"
              v-model="giftSenderTR"
              placeholder="new gift sender"
              class="recreate-form-input"
            />
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedgdTR" />
            <span v-if="checkedgdTR">keep default gift Date </span>
            <datepicker
              v-if="!checkedgdTR"
              :disabled-dates="state.disabledDates"
              input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
              calendar-class="register-calendar"
              v-model="giftDateTR"
              name="giftDateTR"
              ref="recipGiftDateInput"
              format="MM/dd/yyyy"
              placeholder="new gift Date"
            ></datepicker>
          </div>
          <div class="d-row recreacte-row">
            <input type="checkbox" v-model="checkedgmTR" />
            <span v-if="checkedgmTR">keep default gift message </span>
            <textarea
              v-if="!checkedgmTR"
              rows="4"
              style="box-sizing: border-box"
              class="question-title-textarea recreate-form-textarea"
              v-model="giftMessageTR"
            />
          </div>

          <span>Are you sure you want to recreate this order ?</span>
        </div>
        <div class="pop-up-buttons-container">
          <button
            class="pop-up-btn confirm-admin"
            @click="recreateTheAccount()"
          >
            Yes
          </button>
          <button class="pop-up-btn" @click="cancel()">No</button>
        </div>
      </div>
      <div v-if="loading">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <navbar manager="true" admin="true"></navbar>
    <div class="container">
      <div class="route-section">
        <div style="margin-left: 12px">
          <div>
            <div
              class="d-row"
              style="
                justify-content: space-between;
                margin-top: 21px;
                align-items: center;
              "
            >
              <input
                type="text"
                name="orderIdSearch"
                v-model="orderIdSearch"
                placeholder="Enter an Order Id"
                class="chapter-title-input story-form-input"
              />
              <input
                type="email"
                name="emailBuyerSearch"
                v-model="emailBuyerSearch"
                placeholder="Enter an buyer email"
                class="chapter-title-input story-form-input"
              />
              <input
                type="email"
                name="emailReciptSearch"
                v-model="emailReciptSearch"
                placeholder="Enter an Reciptient email"
                class="chapter-title-input story-form-input"
              />
            </div>
            <div
              class="d-row"
              style="
                justify-content: space-between;
                margin-top: 21px;
                align-items: center;
              "
            >
              <button class="btn recreate-btn" @click="searchOrder()">
                Search
              </button>
              <button
                v-if="noResult"
                class="btn recreate-btn"
                @click="confirmRecreateAccount()"
              >
                Create new order
              </button>
            </div>
            <div
              v-if="displayErrorNotFound"
              style="margin-top: 25px; color: red"
            >
              <h3>{{ ErrorNotFoundMessage }}</h3>
            </div>
            <div
              v-if="multiOrderForRecip"
              style="
                margin-top: 18px;
                background: #ffffff;
                border: 1px solid rgba(6, 42, 32, 0.2);
                box-sizing: border-box;
                border-radius: 30px;
                min-height: 300px;
                overflow-y: auto;
                padding: 40px 27px;
              "
            >
              <h4 style="margin-bottom: 20px">
                Choose between this list ,wich order you want to use
              </h4>
              <div class="d-row table-title-row">
                <div class="table-title-element">orderId</div>
                <div class="table-title-element">orderNumber</div>
                <div class="table-title-element">Buyer's email</div>
                <div class="table-title-element">Buyer's Name</div>
                <div class="table-title-element">Recipient’s email</div>
                <div class="table-title-element">Recipient’s Name</div>
                <div class="table-title-element">Number of stories</div>
              </div>
              <div
                v-for="(order, orderIndex) in multirecipOrders"
                :key="orderIndex"
              >
                <div class="d-row table-row">
                  <div class="table-title-element">
                    <span>{{ order.orderId }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.orderNumber }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.buyerEmail }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.buyerName }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.guestEmail }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.guestName }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.numberOfStories }}</span>
                  </div>
                  <div class="table-title-element">
                    <button
                      class="btn recreate-btn"
                      @click="searchOrder(order.orderId, true)"
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="findOrder && order.disabled"
              class="d-row"
              style="margin-bottom: 20px"
            >
              <h4 style="color: crimson; margin: 25px">
                This account is disabled
              </h4>
              <button class="update-btn btn" @click="enableAccount()">
                Enable The Account
              </button>
            </div>
            <div
              v-if="findOrder && order"
              style="margin-top: 18px; color: blue; font-size: x-large"
            >
              <a
                :href="
                  'https://admin.shopify.com/store/racontenous/orders/' +
                  order.orderId
                "
                target="_blank"
                >Order details</a
              >
            </div>
            <div v-if="backToList" style="margin-top: 18px; font-size: x-large">
              <button class="update-btn btn" @click="backToListFunction()">
                -> Back to list
              </button>
            </div>

            <div
              v-if="findOrder && order"
              style="
                margin-top: 18px;
                background: #ffffff;
                border: 1px solid rgba(6, 42, 32, 0.2);
                box-sizing: border-box;
                border-radius: 30px;
                min-height: 300px;
                overflow-y: auto;
                padding: 40px 27px;
              "
            >
              <h2>Account</h2>
              <div class="d-row table-title-row">
                <div class="table-title-element">orderId</div>
                <div class="table-title-element">orderNumber</div>
                <div class="table-title-element">Credit</div>
                <div class="table-title-element">Recipient First Name</div>
                <div class="table-title-element">Recipient Last Name</div>
                <div class="table-title-element">Recipient Gift Date</div>
                <div class="table-title-element">Recipient Gift Sender</div>
                <div class="table-title-element">Recipient Gift Message</div>
                <div class="table-title-element">Mail Frequence</div>
                <div class="table-title-element">Gift Subscription Sent</div>
                <div class="table-title-element">last Question Sent</div>
                <div class="table-title-element">Number of Stories</div>
                <div class="table-title-element">AudioBook</div>
                <div class="table-title-element">PrintStatus</div>
                <div class="table-title-element">Creation Date</div>
                <div class="table-title-element">End Contract</div>
              </div>
              <div>
                <div class="d-row table-row">
                  <div class="table-title-element">
                    <span>{{ order.orderId }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.orderNumber }}</span>
                  </div>
                  <div class="table-title-element">
                    <span class="number-input"
                      ><input
                        class="input-form"
                        type="number"
                        v-model="order.copy"
                        required
                      />
                      <button class="increment" @click="increment">+</button>
                      <button class="decrement" @click="decrement">
                        -
                      </button></span
                    >
                  </div>
                  <div class="table-title-element">
                    <span
                      ><input
                        class="input-form"
                        type="text"
                        v-model="order.recipFName"
                        required
                    /></span>
                  </div>
                  <div class="table-title-element">
                    <span
                      ><input
                        type="text"
                        class="input-form"
                        v-model="order.recipLName"
                        required
                    /></span>
                  </div>
                  <div>
                    <datepicker
                      input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
                      calendar-class="register-calendar"
                      v-model="order.recipGiftDate"
                      name="recipGiftDate"
                      ref="recipGiftDate"
                      format="MM/dd/yyyy"
                      placeholder="recipient Gift Date"
                    ></datepicker>
                  </div>
                  <div class="table-title-element">
                    <span
                      ><input
                        type="text"
                        class="input-form"
                        v-model="order.giftSender"
                        required
                    /></span>
                  </div>
                  <div class="table-title-element">
                    <span>
                      <textarea
                        rows="4"
                        style="box-sizing: border-box"
                        class="question-title-textarea recreate-form-textarea"
                        v-model="order.giftMessage"
                      />
                    </span>
                  </div>
                  <div class="table-title-element">
                    <select
                      class="input-form"
                      v-model="order.mailFrequence"
                      required
                    >
                      <option value="">Select Frequence</option>
                      <option value="1">Every day</option>
                      <option value="2">Once a week</option>
                      <option value="3">Bi-weekly</option>
                      <option value="4">Once every two weeks</option>
                      <option value="5">Once a month</option>
                    </select>
                  </div>
                  <div class="table-title-element">
                    <select
                      class="input-form"
                      v-model="order.giftSubscriptionSent"
                      required
                    >
                      <option value="">Gift Subscription Sent</option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.lastQuestionsent | formatDate }}</span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ nbrStories }}</span>
                  </div>
                  <div class="table-title-element">
                    <select
                      class="input-form"
                      v-model="order.audioBook"
                      required
                    >
                      <option value="">AudioBook</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div class="table-title-element">
                    <select
                      class="input-form"
                      v-model="order.printStatus"
                      required
                    >
                      <option value="">Print Status</option>
                      <option value="writing">Writing</option>
                      <option value="pending">Pending</option>
                      <option value="printing">Printing</option>
                      <option value="done">Done</option>
                    </select>
                  </div>
                  <div class="table-title-element">
                    <span>{{ order.creationDate | formatDate }}</span>
                  </div>
                  <div class="">
                    <datepicker
                      :disabled-dates="state.disabledDates"
                      input-class="datep-gift-input gift-title-input chapter-control recreate-form-input"
                      calendar-class="register-calendar"
                      v-model="order.dateEndContract"
                      name="dateEndContract"
                      ref="dateEndContract"
                      format="MM/dd/yyyy"
                      placeholder="End Contract"
                    ></datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="findOrder && buyer"
              style="
                margin-top: 18px;
                background: #ffffff;
                border: 1px solid rgba(6, 42, 32, 0.2);
                box-sizing: border-box;
                border-radius: 30px;
                min-height: 300px;
                overflow-y: auto;
                padding: 40px 27px;
              "
            >
              <h2>Buyer</h2>
              <div class="d-row table-title-row">
                <div class="table-title-element">Email</div>
                <div class="table-title-element">Name</div>
                <div class="table-title-element">LastLogin</div>
                <div class="table-title-element">Access to the account</div>
              </div>
              <div>
                <div class="d-row table-row">
                  <div class="table-title-element">
                    <input
                      class="input-form"
                      type="email"
                      v-model="buyer.email"
                      required
                    />
                  </div>
                  <div class="table-title-element">
                    <span
                      ><input
                        class="input-form"
                        type="text"
                        v-model="buyer.name"
                        required
                    /></span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ buyer.lastLogin | formatDate }}</span>
                  </div>
                  <div class="table-title-element">
                    <a :href="'/login?plk=' + buyer.tokenSh" target="_blank"
                      >Link to account</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="findOrder && guest"
              style="
                margin-top: 18px;
                background: #ffffff;
                border: 1px solid rgba(6, 42, 32, 0.2);
                box-sizing: border-box;
                border-radius: 30px;
                min-height: 300px;
                overflow-y: auto;
                padding: 40px 27px;
              "
            >
              <h2>Recipient</h2>
              <div class="d-row table-title-row">
                <div class="table-title-element">Email</div>
                <div class="table-title-element">Name</div>
                <div class="table-title-element">LastLogin</div>
                <div class="table-title-element">Access to the account</div>
              </div>
              <div>
                <div class="d-row table-row">
                  <div class="table-title-element">
                    <input
                      class="input-form"
                      type="email"
                      v-model="guest.email"
                      required
                    />
                  </div>
                  <div class="table-title-element">
                    <span
                      ><input
                        class="input-form"
                        type="text"
                        v-model="order.recipFName"
                        required
                    /></span>
                  </div>
                  <div class="table-title-element">
                    <span>{{ guest.lastLogin | formatDate }}</span>
                  </div>
                  <div class="table-title-element">
                    <a :href="'/login?plk=' + guest.tokenSh" target="_blank"
                      >Link to account</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="findOrder && payments.length > 0"
              style="
                margin-top: 18px;
                background: #ffffff;
                border: 1px solid rgba(6, 42, 32, 0.2);
                box-sizing: border-box;
                border-radius: 30px;
                min-height: 300px;
                overflow-y: auto;
                padding: 40px 27px;
              "
            >
              <h2>Payments</h2>
              <div
                v-for="(payment, paymentIndex) in payments"
                :key="paymentIndex"
              >
                <div class="d-row table-row">
                  <div class="table-title-element">
                    {{ payment.createdTime | formatDate }}
                  </div>
                  <div class="table-title-element">
                    <span v-if="excludedTypes.includes(payment.type)">
                      {{ payment.total / 100 }}
                    </span>
                    <span v-else>
                      {{ payment.total }}
                    </span>
                    {{ payment.currency }}
                  </div>
                  <div class="table-title-element">
                    {{
                      payment.type == "ext1year"
                        ? "1 year subscription"
                        : payment.quantity + " copy"
                    }}
                  </div>
                  <div class="table-title-element">
                    {{ buyer.name }}
                  </div>
                  <div class="table-title-element" style="width: 100%">
                    {{ payment.paymentId }}
                  </div>
                  <div class="table-title-element" v-if="payment.refundId">
                    {{
                      payment.total == payment.amountRefunded
                        ? "Refunded"
                        : "Partial Refunded"
                    }}
                  </div>
                  <div class="table-title-element" v-if="!payment.refundId">
                    Paid
                  </div>
                  <div class="table-title-element">
                    <button
                      class="update-btn btn"
                      @click="confirmRefund(payment.paymentId)"
                      :disabled="payment.total == payment.amountRefunded"
                    >
                      Refund
                    </button>
                  </div>
                  <!-- <div class="table-title-element">
                    <button class="update-btn btn" @click="updatePayment()">
                      update Payment
                    </button>
                  </div>-->
                </div>
              </div>
            </div>
            <div
              class="d-row"
              style="
                justify-content: space-between;
                margin-top: 21px;
                align-items: center;
              "
              v-if="findOrder"
            >
              <button class="update-btn btn" @click="updateAccount()">
                Update Account
              </button>
              <button
                v-if="guest"
                class="recreate-btn btn"
                @click="confirmSendGiftEmail()"
              >
                Send Gift Email
              </button>
              <button
                v-if="guest"
                class="recreate-btn btn"
                @click="confirmSendLastQuestion()"
              >
                Send last scheduled question
              </button>
              <button class="reset-btn btn" @click="confirmResetAccount()">
                Reset Questions
              </button>
            </div>
            <div
              class="d-row"
              style="
                justify-content: space-between;
                margin-top: 21px;
                align-items: center;
              "
              v-if="findOrder"
            >
              <button
                v-if="guest"
                class="recreate-btn btn"
                @click="confirmToIWill()"
              >
                Transform to I will
              </button>
              <button
                v-if="!guest"
                class="recreate-btn btn"
                @click="confirmToSomeElseWill()"
              >
                Transform to Someone else will
              </button>
              <button class="delete-btn btn" @click="confirmDelete()">
                Disactivate Account
              </button>
              <button
                class="recreate-btn btn"
                @click="confirmRecreateAccount()"
              >
                Recreate Account
              </button>
            </div>
            <div
              v-if="displayErrorMessage"
              style="color: red; margin-top: 20px"
            >
              <h4>{{ errorMessage }}</h4>
            </div>
            <div
              v-if="displaySuccessMessage"
              style="color: green; margin-top: 20px"
            >
              <h4>{{ successMessage }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "../components/navbar.vue"
import { serverUrl } from "../severUrl"
import dayjs from "dayjs"
import Datepicker from "vuejs-datepicker"
import axiosInstance from "../axiosInstance.js"

const yesterdayDate = dayjs().subtract(1, "day").format("YYYY-MM-DD")

export default {
  created() {},
  components: {
    navbar,
    Datepicker,
  },
  data() {
    return {
      orderIdSearch: "",
      emailBuyerSearch: "",
      emailReciptSearch: "",
      orders: [],
      order: {},
      multirecipOrders: [],
      originalOrders: [],
      buyer: {},
      guest: {},
      showingOverlay: false,
      isRecreateAccount: false,
      loading: false,
      findOrder: false,
      ErrorNotFoundMessage: "",
      displayErrorNotFound: false,
      successMessage: "",
      displaySuccessMessage: false,
      errorMessage: "",
      displayErrorMessage: false,
      isDeletingOrder: false,
      checkedEBTR: true,
      emailBuyerTR: "",
      checkedERTR: true,
      emailReciptientTR: "",
      checkedFNRTR: true,
      fNameReciptientTR: "",
      checkedLNRTR: true,
      lNameReciptientTR: "",
      checkedgfTR: true,
      giftSenderTR: "",
      checkedgmTR: true,
      giftMessageTR: "",
      checkedgdTR: true,
      giftDateTR: "",
      isResetQuestion: false,
      isToIwill: false,
      isToSomeElsewill: false,
      isSendingGiftEmail: false,
      isSendingLastQuestion: false,
      state: {
        disabledDates: {
          to: new Date(yesterdayDate),
        },
      },
      noResult: true,
      multiOrderForRecip: false,
      dateEndContract: "",
      quantity: "",
      copy: "",
      nbrStories: "",
      payments: [],
      emailGuest: "",
      firstNameGuest: "",
      lastNameGuest: "",
      giftDateGuest: "",
      giftSenderBuyer: "",
      giftMessageBuyer:
        "I’ve gifted you a subscription to My Life in a Book so you can write and share your stories with me and the family.",
      amountToRefund: "",
      paymentId: "",
      isSendingRefund: false,
      backToList: false,
      excludedTypes: ["ext1year", "extQuantity"], // Add all the types you want to exclude
    }
  },
  methods: {
    initSearch: function () {
      this.findOrder = false
      this.orders = []
      this.originalOrders = []
      this.multirecipOrders = []
      this.buyer = {}
      this.guest = {}
      this.ErrorNotFoundMessage = ""
      this.successMessage = ""
      this.errorMessage = ""
      this.displayErrorMessage = false
      this.isDeletingOrder = false
      this.checkedEBTR = true
      this.checkedERTR = true
      this.checkedFNRTR = true
      this.checkedLNRTR = true
      this.checkedgfTR = true
      this.checkedgmTR = true
      this.checkedgdTR = true
      this.emailBuyerTR = ""
      this.emailReciptientTR = ""
      this.fNameReciptientTR = ""
      this.lNameReciptientTR = ""
      this.giftSenderTR = ""
      this.giftMessageTR = ""
      this.giftDateTR = ""
      this.quantity = ""
      this.copy = ""
      this.noResult = true
      this.multiOrderForRecip = false
      this.emailGuest = ""
      this.firstNameGuest = ""
      this.lastNameGuest = ""
      this.giftDateGuest = ""
      this.giftSenderBuyer = ""
      this.giftMessageBuyer =
        "I’ve gifted you a subscription to My Life in a Book so you can write and share your stories with me and the family."
    },
    enableAccount: async function () {
      try {
        this.loading = true
        this.showingOverlay = true
        const response = await axiosInstance.post(
          `${serverUrl}/api/orders/enable-account/${this.order?._id}`,
          {
            withCredentials: true,
          }
        )
        if (response.status == 200) {
          this.order.disabled = false
          this.loading = false
          this.showingOverlay = false
          this.successMessage = "order enabled successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in enabled order"
          this.displayErrorMessage = true
        }
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
      }
    },
    backToListFunction: async function () {
      this.backToList = false
      this.orderIdSearch = ""
      await this.searchOrder()
    },
    searchOrder: async function (orderId = null, backToList = false) {
      try {
        this.backToList = backToList
        this.order = {}
        this.originalOrder = {}
        this.multirecipOrders = []
        if (orderId) this.orderIdSearch = orderId

        this.multiOrderForRecip = false
        this.findOrder = false
        this.loading = true
        this.showingOverlay = true
        this.displayErrorNotFound = false
        this.displayErrorMessage = false
        this.displaySuccessMessage = false
        let response
        if (this.orderIdSearch) {
          response = await axiosInstance.post(
            serverUrl + "/api/orders/get-users-by-orderid",
            { orderIdSearch: this.orderIdSearch },
            {
              withCredentials: true,
            }
          )
        } else if (this.emailReciptSearch) {
          response = await axiosInstance.post(
            serverUrl + "/api/orders/get-users-by-email-recip",
            { emailReciptSearch: this.emailReciptSearch },
            {
              withCredentials: true,
            }
          )
        } else if (this.emailBuyerSearch) {
          response = await axiosInstance.post(
            serverUrl + "/api/orders/get-users-by-orderid-email",
            { emailBuyerSearch: this.emailBuyerSearch },
            {
              withCredentials: true,
            }
          )
        }
        if (
          response.status == 200 &&
          (response?.data?.orders || response?.data?.order)
        ) {
          if (response?.data?.multiOrderForRecip) {
            this.multiOrderForRecip = true
            const ordersRecip = response?.data?.orders
            ordersRecip?.map((order) => {
              if (order?.orderId) {
                this.multirecipOrders.push({
                  orderId: order?.orderId,
                  orderNumber: order?.orderNumber,
                  buyerEmail: order?.buyerEmail,
                  buyerName: order?.buyerName,
                  guestEmail: order?.guestEmail,
                  guestName: order?.guestName,
                  numberOfStories: order?.numberOfStories,
                })
              }
            })
          } else {
            this.multiOrderForRecip = false
            this.findOrder = true
            this.order = response?.data?.order
            this.order.dateEndContract = this.calculateEndDate(this.order)
            this.originalOrder = response?.data?.order
            this.originalOrder.dateEndContract = this.calculateEndDate(
              this.order
            )
            this.buyer = response?.data?.buyer
            this.guest = response?.data?.guest
            this.copy = this.order.copy
            this.quantity = this.order.quantity
            this.noResult = false
            this.nbrStories = response?.data?.nbrStories
            this.payments = response?.data?.payments
          }
        } else {
          this.ErrorNotFoundMessage = "Order not found"
          this.displayErrorNotFound = true
          this.noResult = true
        }

        this.loading = false
        this.showingOverlay = false
      } catch (error) {
        this.ErrorNotFoundMessage = "Order not found"
        this.displayErrorNotFound = true
        this.loading = false
        this.showingOverlay = false
        this.noResult = true
      }
    },
    confirmDelete: function () {
      this.showingOverlay = true
      this.isDeletingOrder = true
    },
    confirmResetAccount: function () {
      this.showingOverlay = true
      this.isResetQuestion = true
    },
    confirmRecreateAccount: function () {
      if (this.orderIdSearch) {
        this.showingOverlay = true
        this.isRecreateAccount = true
      } else {
        alert("you should enter an order ID ")
      }
    },

    confirmToIWill: function () {
      this.showingOverlay = true
      this.isToIwill = true
    },
    confirmToSomeElseWill: function () {
      this.showingOverlay = true
      this.isToSomeElsewill = true
    },
    confirmSendGiftEmail: function () {
      this.showingOverlay = true
      this.isSendingGiftEmail = true
    },
    confirmRefund: function (paymentId) {
      this.showingOverlay = true
      this.isSendingRefund = true
      this.paymentId = paymentId
    },
    confirmSendLastQuestion: function () {
      this.showingOverlay = true
      this.isSendingLastQuestion = true
    },
    cancel: function () {
      this.showingOverlay = false
      this.isDeletingOrder = false
      this.isRecreateAccount = false
      this.isSendingLastQuestion = false
      this.isSendingGiftEmail = false
      this.isSendingRefund = false
      this.isResetQuestion = false
      this.isToIwill = false
      this.isToSomeElsewill = false
      this.amountToRefund = ""
      this.paymentId = ""
    },
    refund: async function () {
      try {
        this.loading = true
        this.showingOverlay = true

        this.isSendingRefund = false
        const res = await axiosInstance.post(
          `${serverUrl}/api/payment/refund`,
          {
            paymentId: this.paymentId,
            amount: this.amountToRefund ? this.amountToRefund * 100 : "",
          },
          { withCredentials: true }
        )
        if (res?.status == 200) {
          this.successMessage = "order refunded"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error with refund"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.amountToRefund = ""
        this.paymentId = ""
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isSendingRefund = false
        this.amountToRefund = ""
        this.paymentId = ""
      }
    },
    resetQuestion: async function () {
      try {
        this.loading = true
        const res = await axiosInstance.post(
          `${serverUrl}/api/orders/reset-question`,
          {
            buyerId: this.order.buyerId,
            orderId: this.order._id,
          },
          { withCredentials: true }
        )
        if (res?.status == 200) {
          this.successMessage = "order already recreated successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in recreat order"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.isResetQuestion = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isResetQuestion = false
      }
    },
    deleteAccount: async function () {
      try {
        this.loading = true
        const response = await axiosInstance.delete(
          serverUrl + `/api/orders/delete-order/${this.order.orderId}`,
          {
            withCredentials: true,
          }
        )
        if (response?.status == 200) {
          this.initSearch()
          this.successMessage = "delete completed successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in delete account"
          this.displayErrorMessage = true
        }
        this.showingOverlay = false
        this.loading = false
        this.isDeletingOrder = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isDeletingOrder = false
      }
    },
    recreateTheAccount: async function () {
      const isValidForm = this.isValidFormToRecreate()
      if (isValidForm) {
        this.loading = true
        try {
          const recreate = await axiosInstance.post(
            serverUrl + "/api/orders/recreat-order",
            {
              orderIdSearch: this.orderIdSearch,
              emailBuyerTR: !this.checkedEBTR ? this.emailBuyerTR : "",
              emailReciptientTR: !this.checkedERTR
                ? this.emailReciptientTR
                : "",
              fNameReciptientTR: !this.checkedFNRTR
                ? this.fNameReciptientTR
                : "",
              lNameReciptientTR: !this.checkedLNRTR
                ? this.lNameReciptientTR
                : "",
              giftSenderTR: !this.checkedgfTR ? this.giftSenderTR : "",
              giftMessageTR: !this.checkedgmTR ? this.giftMessageTR : "",
              giftDateTR: !this.checkedgdTR ? this.giftDateTR : "",
            },
            {
              withCredentials: true,
            }
          )
          if (recreate?.status == 200) {
            this.initSearch()
            this.successMessage = "order already recreated successfully"
            this.displaySuccessMessage = true
          } else {
            this.errorMessage = "error in recreat order"
            this.displayErrorMessage = true
          }

          this.loading = false
          this.showingOverlay = false
          this.isRecreateAccount = false
        } catch (e) {
          this.loading = false
          this.showingOverlay = false
          this.isRecreateAccount = false
        }
      } else {
        this.loading = false
        alert("please make sure that the form is valid ")
      }
    },
    isValidFormToRecreate: function () {
      const mailformat = /^\w+([+.-]?\w+)*@\w+([+.-]?\w+)*(\.\w{2,5})+$/
      if (!this.checkedEBTR) {
        if (
          this.emailBuyerTR?.length > 50 ||
          !this.emailBuyerTR.match(mailformat)
        ) {
          return false
        }
      }
      if (!this.checkedERTR) {
        if (
          this.emailReciptientTR?.length > 50 ||
          !this.emailReciptientTR.match(mailformat)
        ) {
          return false
        }
      }
      return true
    },
    isValidFormToUpdate: function () {
      const mailformat = /^\w+([+.-]?\w+)*@\w+([+.-]?\w+)*(\.\w{2,5})+$/
      if (
        this.buyer?.email?.length > 50 ||
        !this.buyer?.email.match(mailformat) ||
        !this.buyer?.name
      ) {
        return false
      }
      if (this.guest1Id) {
        if (
          this.guest?.email?.length > 50 ||
          !this.guest?.email.match(mailformat) ||
          !this.guest?.name ||
          !this.guest?.email
        ) {
          return false
        }
      }

      return true
    },

    updateAccount: async function () {
      try {
        this.displayErrorMessage = false
        this.displaySuccessMessage = false

        if (this.order?.copy != this.copy) {
          this.order.quantity =
            this.order.quantity + (this.order?.copy - this.copy)
        }
        if (this.order.printStatus == "writing" && this.order.copy < 1) {
          this.errorMessage = "Credit cannot be 0 when printStatus is writing"
          this.displayErrorMessage = true
        } else {
          const isValidForm = this.isValidFormToUpdate()
          if (isValidForm) {
            const response = await axiosInstance.put(
              serverUrl + "/api/orders/update-order",
              this.order,
              {
                withCredentials: true,
              }
            )
            if (this.buyer) {
              this.buyer.orderIdS = this.orderIdSearch
              this.buyer.isBuyer = 1
              await axiosInstance.put(serverUrl + "/api/users", this.buyer, {
                withCredentials: true,
              })
            }
            if (this.guest) {
              this.guest.orderIdS = this.orderIdSearch
              this.guest.isBuyer = 0
              await axiosInstance.put(serverUrl + "/api/users", this.guest, {
                withCredentials: true,
              })
            }
            if (response?.status == 200) {
              this.successMessage = "update completed successfully"
              this.displaySuccessMessage = true
              this.originalOrder = this.order
              this.quantity = this.order.quantity
              this.copy = this.order.copy
            } else {
              this.errorMessage = "error in update"
              this.displayErrorMessage = true
            }
          } else {
            this.errorMessage =
              "Make sure that all field are well filled out and respect the requested format"
            this.displayErrorMessage = true
          }
        }
      } catch (e) {
        this.errorMessage = e?.message
        this.displayErrorMessage = true
      }
    },
    updatePayment: async function () {
      try {
        this.loading = true
        await axiosInstance.post(
          serverUrl + "/api/payment/update-payment",
          this.order,
          {
            withCredentials: true,
          }
        )
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log("err", err.message)
      }
    },
    transformToIWill: async function () {
      this.loading = true
      try {
        delete this.order.guest1Id
        delete this.order.guest2Id
        this.order.iwill = true
        const response = await axiosInstance.put(
          serverUrl + "/api/orders/update-order",
          this.order,
          {
            withCredentials: true,
          }
        )
        if (response?.status == 200) {
          this.initSearch()
          this.successMessage = "account transform on i will successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in transform to i will"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.isToIwill = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isToIwill = false
        this.errorMessage = e?.message
        this.displayErrorMessage = true
      }
    },
    transformToSomeElseWill: async function () {
      this.loading = true
      try {
        const response = await axiosInstance.post(
          serverUrl + "/api/orders/transform-some-else",
          {
            orderId: this.order?._id,
            emailGuest: this.emailGuest,
            firstNameGuest: this.firstNameGuest,
            lastNameGuest: this.lastNameGuest,
            giftDateGuest: this.giftDateGuest,
            giftSenderBuyer: this.giftSenderBuyer,
            giftMessageBuyer: this.giftMessageBuyer,
          },
          {
            withCredentials: true,
          }
        )
        if (response?.status == 200 && response?.data?.success) {
          this.initSearch()
          this.successMessage =
            "account transform on some else will successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in transform to some else will"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.isToSomeElsewill = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isToSomeElsewill = false
        this.errorMessage = e?.message
        this.displayErrorMessage = true
      }
    },
    sendLastScudeledQ: async function () {
      try {
        const response = await axiosInstance.post(
          serverUrl + "/api/users/send-last-question",
          { orderId: this.order?._id },
          {
            withCredentials: true,
          }
        )
        if (response?.status == 200) {
          this.successMessage = "Last scheduled question sent successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in sending Last scheduled question"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.isSendingLastQuestion = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isSendingLastQuestion = false
        this.errorMessage = e?.message
        this.displayErrorMessage = true
      }
    },
    sendGiftEmail: async function () {
      try {
        const response = await axiosInstance.post(
          serverUrl + "/api/users/sendgiftmail",
          { orderId: this.order?._id },
          {
            withCredentials: true,
          }
        )
        if (response?.status == 200) {
          this.successMessage = "gift email sent successfully"
          this.displaySuccessMessage = true
        } else {
          this.errorMessage = "error in sending gift email"
          this.displayErrorMessage = true
        }
        this.loading = false
        this.showingOverlay = false
        this.isSendingGiftEmail = false
      } catch (e) {
        this.loading = false
        this.showingOverlay = false
        this.isSendingGiftEmail = false
        this.errorMessage = e?.message
        this.displayErrorMessage = true
      }
    },
    calculateEndDate: (order) => {
      order.dateEndContract = order?.dateEndContract
        ? dayjs(order?.dateEndContract).format("MM/DD/YYYY")
        : order?.recipGiftDate
        ? dayjs(order?.recipGiftDate).add(1, "year").format("MM/DD/YYYY")
        : dayjs(order?.creationDate).add(1, "year").format("MM/DD/YYYY")
      return order.dateEndContract
    },
    increment() {
      this.order.copy = Number(this.order.copy) + 1
    },
    decrement() {
      if (this.order.copy > 0) {
        this.order.copy = Number(this.order.copy) - 1
      }
    },
  },
  filters: {
    formatDate: (date) => {
      if (!date) {
        return null
      }
      return dayjs(date).format("MM/DD/YYYY")
    },
  },
  computed: {
    filteredOrders() {
      return this.calculateEndDate(this.order)
    },
  },
}
</script>
<style>
.btn {
  padding: 10px 23px;
  color: rgba(6, 42, 32, 0.4);
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  margin-right: 10px;
}
.btn:hover {
  cursor: pointer;
}
.btn.active {
  background: #0f172a;
  color: white;
  font-weight: 500;
}

.story-form-input {
  padding: 20px;
  margin-right: 10px;
}
.input-form {
  padding: 10px;
  border: solid 1px #00000017;
}
.table-title-element {
  margin-right: 5px;
  text-align: center;
  min-width: 151px;
  margin-left: 10px;
}
.recreacte-row {
  padding: 10px;
}
.recreacte-row span {
  margin-left: 10px;
}
.recreate-form-input {
  padding: 10px;
  margin-left: 10px;
}
.recreate-form-textarea {
  margin-left: 10px;
}
.table-title-element {
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  text-overflow: ellipsis; /* Ajoute des points de suspension à la fin du texte qui dépasse */
  min-width: 200px; /* Définir une largeur minimale pour chaque colonne */
}

.number-input {
  position: relative;
  display: inline-block;
}

.number-input input {
  width: 100px;
  padding: 5px 25px;
  text-align: center;
}

.number-input button {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.number-input .increment {
  right: 0;
}

.number-input .decrement {
  left: 0;
}
</style>
